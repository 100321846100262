import { segmentTrackMoreDetailsConstraintsDialogOpened } from "analytics/analyticTrackEvents";
import AvailableTag from "components/common/AvailableTag";
import { getAllSizeLabels, getLabelStyle } from "components/common/labels";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import {
    useMapViewRoutingMetadata,
    usePricedConstraintConfig
} from "contexts/RoutingMetadataContext";
import useSelectedScopedBus from "contexts/SelectedScopedBusContext/useSelectedScopedBus";
import { Constraint_Stacks_Bool_Exp } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import React, { useEffect, useState } from "react";
import {
    Constraint,
    convertHasuraConstraintsToConstraints
} from "types/constraintType";
import { STORAGE_TYPE } from "types/generatorType";
import { Loading } from "types/loadingType";
import {
    CAPACITY_RESOURCE_TYPE,
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE
} from "types/resourceType";
import BusDetailPane, { PowerAmounts } from "./BusDetailPane";
import ConnectedLinesSection from "./ConnectedLinesSection";
import ConstraintDetailsDialog from "./ConstraintDetailsDialog/ConstraintDetailsDialog";
import ConstraintDetailsDialogTable, {
    ConstraintTableConfig
} from "./ConstraintDetailsDialog/ConstraintsDetailsDialogTable";
import { getExportConstraintsToCSV } from "./ConstraintDetailsDialog/constraintsTableHelpers";
import ConstraintsDetailsSection from "./ConstraintsDetailsSection";
import {
    getAllocatedCost,
    getConstraintDetailRows,
    getFormattedAllocatedCost,
    getFormattedTotalCost,
    getTotalCost
} from "./constraintsHelpers";
import CustomInjectionDialog from "./CustomInjectionDialog";

type ConstraintsBasedBusDetailPaneProps = {
    readonly unlockBusLoading: boolean;
};

const GET_CONSTRAINTS = loader("src/graphql/getConstraints.graphql");

// The only region that has charging and uses this component (based on cost) is ISO-NE.
// We'll need to figure out a different labelStyle when we support showing all three of
//  discharging, capacity, and charging simultaneously
const ConstraintsBasedBusDetailPane: React.FC<
    ConstraintsBasedBusDetailPaneProps
> = (props: ConstraintsBasedBusDetailPaneProps) => {
    const { unlockBusLoading } = props;
    const { generator, scopeView, componentConfig } =
        useMapViewRoutingMetadata();
    const {
        hasAllocatedCost,
        showCapacity,
        showContingency,
        showChargingSize
    } = usePricedConstraintConfig();
    const scopedBus = useSelectedScopedBus();

    const {
        busFiltersConfiguration: {
            busFilters: { scope, includePreExistingConstraints }
        }
    } = useMapViewConfiguration();

    const shouldShowCharging = generator === STORAGE_TYPE && showChargingSize;

    const [detailedConstraintsDialogOpen, setDetailedConstraintsDialogOpen] =
        useState(false);
    const [customInjectionDialogOpen, setCustomInjectionDialogOpen] =
        useState(false);
    const [editedPowerAmounts, setEditedPowerAmounts] = useState<PowerAmounts>(
        scopedBus.scopedCapacityEnergyCost
    );

    useEffect(() => {
        setEditedPowerAmounts(scopedBus.scopedCapacityEnergyCost);
    }, [
        scopedBus.bus.id,
        scopedBus.scopedCapacityEnergyCost.capacitySize,
        scopedBus.scopedCapacityEnergyCost.energySize,
        scopedBus.scopedCapacityEnergyCost.chargingSize,
        scope
    ]);

    const originalPowerAmounts: PowerAmounts =
        scopedBus.scopedCapacityEnergyCost;

    const getConstraintsQueryVariables: Constraint_Stacks_Bool_Exp = {
        bus_id: { _eq: scopedBus.bus.id },
        scope: { _eq: scope },
        scope_view: { _eq: scopeView }
    };

    if (!includePreExistingConstraints) {
        getConstraintsQueryVariables.is_pre_existing = {
            _eq: false
        };
    }

    const maybeConstraints = getHasuraDataAndConvertToNiraType(
        GET_CONSTRAINTS,
        convertHasuraConstraintsToConstraints,
        { constraintsWhereClause: getConstraintsQueryVariables },
        unlockBusLoading
    );

    const constraintTableConfig: ConstraintTableConfig = {
        hideAllocatedCosts: !hasAllocatedCost,
        hideTotalCosts: false,
        showPostStudyLoad: true,
        showContingency: showContingency,
        generatorType: generator,
        missingBranchMetadata: componentConfig.missingBranchMetadata,
        hideResourceType: componentConfig.hideResourceType
    };

    const labelStyle = getLabelStyle(componentConfig, generator);

    const exportConstraints = getExportConstraintsToCSV(
        scopedBus.bus.busDisplayName,
        maybeConstraints,
        {
            constraintTableConfig,
            labelStyle,
            powerAmounts: editedPowerAmounts
        }
    );

    return (
        <>
            <BusDetailPane
                energyTag={
                    <AvailableTag
                        size={originalPowerAmounts.energySize}
                        editedSize={editedPowerAmounts.energySize}
                        onClick={() => setCustomInjectionDialogOpen(true)}
                        resourceType={ENERGY_RESOURCE_TYPE}
                        selectedId={scopedBus.bus.id}
                        labelStyle={labelStyle}
                    />
                }
                chargingTag={
                    shouldShowCharging && (
                        <AvailableTag
                            size={originalPowerAmounts.chargingSize}
                            editedSize={editedPowerAmounts.chargingSize}
                            onClick={() => setCustomInjectionDialogOpen(true)}
                            resourceType={CHARGING_RESOURCE_TYPE}
                            selectedId={scopedBus.bus.id}
                            labelStyle={labelStyle}
                        />
                    )
                }
                capacityTag={
                    showCapacity && (
                        <AvailableTag
                            size={originalPowerAmounts.capacitySize}
                            editedSize={editedPowerAmounts.capacitySize}
                            onClick={() => setCustomInjectionDialogOpen(true)}
                            resourceType={CAPACITY_RESOURCE_TYPE}
                            selectedId={scopedBus.bus.id}
                            labelStyle={labelStyle}
                        />
                    )
                }
                constraintDetailsSections={[
                    <ConstraintDetailsSection
                        maybeConstraints={maybeConstraints}
                        originalEnergyAndCapacity={originalPowerAmounts}
                        editedEnergyAndCapacity={editedPowerAmounts}
                        setDetailedConstraintsDialogOpen={
                            setDetailedConstraintsDialogOpen
                        }
                    />
                ]}
                connectedLinesSection={
                    <ConnectedLinesSection
                        isUnlockBusLoading={unlockBusLoading}
                    />
                }
            />
            <CustomInjectionDialog
                isOpen={customInjectionDialogOpen}
                onClose={() => {
                    setCustomInjectionDialogOpen(false);
                }}
                defaultPowerAmounts={editedPowerAmounts}
                setPowerAmounts={setEditedPowerAmounts}
                getFirstSummaryField={(powerAmounts) =>
                    hasAllocatedCost
                        ? getFormattedAllocatedCost(
                              getAllocatedCost(maybeConstraints, powerAmounts)
                          )
                        : undefined
                }
                getSecondSummaryField={(powerAmounts) =>
                    getFormattedTotalCost(
                        getTotalCost(maybeConstraints, powerAmounts)
                    )
                }
                selectedId={scopedBus.bus.id}
            />
            <ConstraintDetailsDialog
                isOpen={detailedConstraintsDialogOpen}
                closeDialog={() => {
                    setDetailedConstraintsDialogOpen(false);
                }}
                exportConstraints={exportConstraints}
                title={`Constraints triggered @ ${getAllSizeLabels(
                    editedPowerAmounts,
                    showCapacity,
                    shouldShowCharging,
                    labelStyle
                )}`}
                selectedId={scopedBus.bus.id}
            >
                <ConstraintDetailsDialogTable
                    constraints={maybeConstraints}
                    powerAmounts={editedPowerAmounts}
                    showAllConstraints={false}
                    labelStyle={labelStyle}
                    showCapacityLabel={showCapacity}
                    showChargingLabel={shouldShowCharging}
                    constraintTableConfig={constraintTableConfig}
                />
            </ConstraintDetailsDialog>
        </>
    );
};

interface ConstraintDetailsSectionProps {
    maybeConstraints: Loading<ReadonlyArray<Constraint>>;
    originalEnergyAndCapacity: PowerAmounts;
    editedEnergyAndCapacity: PowerAmounts;
    setDetailedConstraintsDialogOpen: (isOpen: boolean) => void;
}
const ConstraintDetailsSection: React.FC<ConstraintDetailsSectionProps> = ({
    maybeConstraints,
    originalEnergyAndCapacity,
    editedEnergyAndCapacity,
    setDetailedConstraintsDialogOpen
}: ConstraintDetailsSectionProps) => {
    const scopedBus = useSelectedScopedBus();
    const { hasAllocatedCost } = usePricedConstraintConfig();

    const constraintDetailsRows = getConstraintDetailRows(
        maybeConstraints,
        originalEnergyAndCapacity,
        editedEnergyAndCapacity,
        hasAllocatedCost
    );

    return (
        <ConstraintsDetailsSection
            sectionLabel="Triggered constraints"
            onHeaderDetailsClicked={() => {
                segmentTrackMoreDetailsConstraintsDialogOpened(
                    scopedBus.bus.id
                );
                setDetailedConstraintsDialogOpen(true);
            }}
            constraintDetailsRows={constraintDetailsRows}
        />
    );
};

export default ConstraintsBasedBusDetailPane;
