import { useScenarioResults } from "in_queue/contexts/ScenarioResultsContext";
import { ScenarioConstraint } from "in_queue/types/scenarioResultType";
import { mapLoadingState } from "types/loadingType";

import { AgGridReact } from "ag-grid-react";
import { useRegionConfig } from "in_queue/contexts/ClusterContext";
import { useCurrentProjectId } from "in_queue/contexts/ScenarioPageContext";
import { InQueueRegionConfig } from "in_queue/types/configPerRegion";
import { useRef, useState } from "react";
import {
    AgGridColumnDefs,
    ConstraintsTableColumnId,
    constraintTypeFormatter,
    dfaxFormatter,
    hideUnwantedColumns,
    percentValueFormatter,
    roundingFormatter,
    stringFormatter
} from "./agGrid/agGridHelpers";
import { BaseAgGrid } from "./base/BaseAgGrid";
import { ExportButton } from "./ribbon/ExportButton";
import { TableActionRibbon } from "./ribbon/TableActionRibbon";
import { ViewType, ViewTypesMenu } from "./ribbon/ViewTypesMenu";
import css from "./TableStyles.module.scss";

export const ScenarioConstraintsTable: React.FC<{ filterText: string }> = ({
    filterText
}) => {
    const gridRef = useRef<AgGridReact<ScenarioConstraint>>(null);
    const scenarioResults = useScenarioResults();
    const componentConfig = useRegionConfig();
    const currentProjectId = useCurrentProjectId();

    const [viewType, setViewType] = useState<ViewType>("current");
    const leftActions = [
        <ViewTypesMenu
            currentViewType={viewType}
            setCurrentViewType={setViewType}
            validViewTypes={["current", "related", "all"]}
        />
    ];
    const rightActions = [
        <ExportButton gridRef={gridRef} exportName="Constraints" />
    ];

    const constraints = mapLoadingState(scenarioResults, (d) => d.constraints);
    return (
        <div className={css["table-ribbon-wrapper"]}>
            <TableActionRibbon
                leftActions={leftActions}
                rightActions={rightActions}
            />
            <BaseAgGrid<ScenarioConstraint>
                ref={gridRef}
                loadingData={constraints}
                columnDefs={getColumnDefs(componentConfig)}
                quickFilterText={filterText}
                isExternalFilterPresent={() => viewType !== "all"}
                doesExternalFilterPass={(node) => {
                    if (
                        viewType === "related" &&
                        scenarioResults !== "loading"
                    ) {
                        const relatedProjectIds =
                            scenarioResults.relatedProjectIds;
                        return (
                            node.data != null &&
                            relatedProjectIds.has(node.data.projectId)
                        );
                    } else {
                        return node.data?.projectId === currentProjectId;
                    }
                }}
            />
        </div>
    );
};

const getColumnDefs = (config: InQueueRegionConfig) => {
    const columnDefs: AgGridColumnDefs<
        ScenarioConstraint,
        ConstraintsTableColumnId
    > = [
        {
            colId: "projectId",
            field: "projectId",
            headerName: "Project",
            width: 90,
            pinned: true,
            filter: true
        },
        {
            colId: "monitoredFacilityName",
            field: "monitoredFacilityName",
            headerName: "Monitored facility",
            pinned: true,
            filter: true,
            width: 240
        },
        {
            colId: "constraintType",
            field: "constraintType",
            headerName: "Type",
            filter: true,
            width: 100,
            valueFormatter: constraintTypeFormatter
        },
        {
            colId: "dfax",
            field: "dfax",
            headerName: "Dfax",
            width: 70,
            filter: true,
            valueFormatter: dfaxFormatter
        },
        {
            colId: "mwImpact",
            field: "mwImpact",
            headerName: "MW Impact",
            width: 120,
            filter: true,
            valueFormatter: roundingFormatter
        },
        {
            headerName: "Load",
            children: [
                {
                    colId: "preStudyLoad",
                    field: "preStudyLoad",
                    headerName: config.loadLabel.benchLoad,
                    width: 100,
                    valueFormatter: percentValueFormatter
                },
                {
                    colId: "postStudyLoad",
                    field: "postStudyLoad",
                    headerName: config.loadLabel.studyLoad,
                    width: 110,
                    valueFormatter: percentValueFormatter
                }
            ]
        },
        {
            headerName: "Flow (MVA)",
            children: [
                {
                    colId: "preStudyFlow",
                    field: "preStudyFlow",
                    headerName: "Base",
                    width: 80,
                    valueFormatter: roundingFormatter
                },
                {
                    colId: "postStudyFlow",
                    field: "postStudyFlow",
                    headerName: "Study",
                    width: 80,
                    valueFormatter: roundingFormatter
                }
            ]
        },
        {
            colId: "rating",
            field: "postStudyRating",
            headerName: "Rating (MVA)",
            width: 120
        },
        {
            colId: "worstContingency",
            field: "worstContingencyName",
            headerName: "Worst contingency"
        },
        {
            colId: "facilityDetails",
            field: "facilityDetails",
            headerName: "Facility type",
            width: 155,
            valueFormatter: stringFormatter
        },
        {
            colId: "networkUpgrades",
            field: "networkUpgrades",
            headerName: "Associated upgrades"
        },
        {
            colId: "model",
            field: "model",
            headerName: "Model",
            valueFormatter: stringFormatter,
            autoHeight: true,
            wrapText: true,
            width: 200
        },
        {
            colId: "notes",
            field: "notes",
            headerName: "Notes",
            valueFormatter: stringFormatter,
            autoHeight: true,
            wrapText: true,
            width: 300
        }
    ];

    hideUnwantedColumns(columnDefs, config.constraintsTable.columnsToRemove);

    return columnDefs;
};
