import {
    Button,
    FormGroup,
    MenuItem,
    Radio,
    RadioGroup
} from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import React from "react";
import {
    GeneratorType,
    GENERATOR_TYPE_TO_DEFAULT_LABEL,
    getLabelForRegionAndGeneratorType,
    LOAD_TYPE,
    SOLAR_TYPE,
    STORAGE_TYPE,
    WIND_TYPE
} from "types/generatorType";
import {
    CAISO,
    DUKE,
    ERCOT,
    ISONE,
    MISO,
    NYISO,
    PJM,
    RegionType,
    SOCO,
    SPP,
    TVA,
    WECC
} from "types/regionType";
import "./RegionSelectorTab.scss";

import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { LOAD_VIEW_ENABLED } from "types/featureFlagType";
import { REGION_TO_FUEL_TYPE_TO_URL_DICT } from "types/urlDirectoryType";

const REGION_TO_DEFAULT_FUEL_TYPE: { [K in RegionType]?: GeneratorType } = {
    PJM: SOLAR_TYPE,
    WECC: SOLAR_TYPE,
    TVA: SOLAR_TYPE,
    SOCO: SOLAR_TYPE
};

type RegionSelectorTabProps = {
    readonly stagingRegionType: RegionType | undefined;
    readonly stagingGeneratorType: GeneratorType | undefined;
    readonly setStagingRegionType: (regionType: RegionType | undefined) => void;
    readonly setStagingGeneratorType: (
        generatorType: GeneratorType | undefined
    ) => void;
};

const SORTED_REGIONS: RegionType[] = [
    PJM,
    MISO,
    SPP,
    ERCOT,
    WECC,
    NYISO,
    ISONE,
    TVA,
    SOCO,
    DUKE,
    CAISO
];

const RegionSelectorTab: React.FC<RegionSelectorTabProps> = (props) => {
    const {
        stagingRegionType,
        stagingGeneratorType,
        setStagingRegionType,
        setStagingGeneratorType
    } = props;

    const user = useUserDataContext();

    const allowedRegions: Set<RegionType> = new Set(
        user.subscriptions.map((subscription) => subscription.region)
    );

    return (
        <div>
            <FormGroup label="Region">
                <Select
                    items={SORTED_REGIONS}
                    itemRenderer={getItemRenderer(allowedRegions)}
                    onItemSelect={(region: RegionType) => {
                        setStagingRegionType(region);
                        setStagingGeneratorType(
                            REGION_TO_DEFAULT_FUEL_TYPE[region]
                        );
                    }}
                    filterable={false}
                    menuProps={{
                        className: "RegionSelectorTab-region-menu"
                    }}
                    popoverProps={{ minimal: true }}
                >
                    <Button
                        className="RegionSelectorTab-region-dropdown"
                        text={stagingRegionType ?? "Select region"}
                        rightIcon="caret-down"
                    />
                </Select>
            </FormGroup>
            <FormGroup label="Fuel type">
                <RadioGroup
                    onChange={(evt) => {
                        setStagingGeneratorType(
                            evt.currentTarget.value as GeneratorType
                        );
                    }}
                    inline={true}
                    selectedValue={stagingGeneratorType}
                >
                    {getRadioButton(SOLAR_TYPE, stagingRegionType)}
                    {getRadioButton(WIND_TYPE, stagingRegionType)}
                    {getRadioButton(STORAGE_TYPE, stagingRegionType)}
                    {user.featureFlags.includes(LOAD_VIEW_ENABLED) &&
                        getRadioButton(LOAD_TYPE, stagingRegionType)}
                </RadioGroup>
            </FormGroup>
        </div>
    );
};

const getRadioButton = (
    generatorType: GeneratorType,
    stagedRegionType: RegionType | undefined
) => {
    // If the region has been picked, but the generator type is unavailable,
    // we dont render the radio.
    if (stagedRegionType !== undefined) {
        const generatorAndRegionSupported =
            REGION_TO_FUEL_TYPE_TO_URL_DICT[stagedRegionType][generatorType] !==
            null;
        if (!generatorAndRegionSupported) {
            return null;
        }
    }

    return (
        <Radio
            value={generatorType}
            label={
                // If we haven't picked a region, just display the default gen type labels
                stagedRegionType === undefined
                    ? GENERATOR_TYPE_TO_DEFAULT_LABEL[generatorType]
                    : getLabelForRegionAndGeneratorType(
                          stagedRegionType,
                          generatorType
                      )
            }
            disabled={stagedRegionType === undefined}
        />
    );
};

const getItemRenderer: (
    allowedRegions: Set<RegionType>
) => ItemRenderer<RegionType> = (allowedRegions: Set<RegionType>) => {
    return (region: RegionType, { handleClick, modifiers }) => {
        const partOfSubscription = allowedRegions.has(region);
        return (
            <MenuItem
                active={modifiers.active}
                key={region}
                text={
                    partOfSubscription ? region : `${region} (Not subscribed)`
                }
                onClick={handleClick}
                disabled={!partOfSubscription}
            />
        );
    };
};

export default RegionSelectorTab;
