import {
    Collapse,
    Icon,
    Intent,
    OverlayToaster,
    Position,
    Text
} from "@blueprintjs/core";
import {
    segmentTrackConnectedLinesSectionToggled,
    segmentTrackLineSelectedFromBusDetailPane
} from "analytics/analyticTrackEvents";
import classNames from "classnames";
import AvailableTag from "components/common/AvailableTag";
import { getLabelStyle, LabelStyleType } from "components/common/labels";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useConnectedLinesData } from "contexts/MapViewDataContext/hooks/useConnectedLinesData";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import useSelectedScopedBus from "contexts/SelectedScopedBusContext/useSelectedScopedBus";
import React, { useState } from "react";
import { getCapacityColor, GREY_COLOR } from "sharedStyles/capacityColorStyles";
import { isPricedConstraintConfig } from "types/componentConfigPerRegion";
import { STORAGE_TYPE } from "types/generatorType";
import { ConnectedLine, LineWithMissingBus } from "types/modelLinesType";
import {
    CAPACITY_RESOURCE_TYPE,
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE
} from "types/resourceType";
import "./ConnectedLinesSection.scss";
import {
    getFormattedAllocatedCost,
    getFormattedTotalCost
} from "./constraintsHelpers";

const ConnectedLinesSectionToaster = OverlayToaster.create({
    position: Position.TOP
});

interface ConnectedLinesSectionProps {
    readonly isUnlockBusLoading?: boolean;
    readonly isLocked?: boolean;
}

const ConnectedLinesSection: React.FC<ConnectedLinesSectionProps> = (props) => {
    const { isUnlockBusLoading, isLocked } = props;
    const {
        connectedLines,
        linesWithMissingBuses,
        isLoading: isConnectedLinesLoading
    } = useConnectedLinesData({ isUnlockBusLoading });
    const { bus: selectedBus } = useSelectedScopedBus();

    const [isOpen, setOpen] = useState(false);
    const isLoading = isUnlockBusLoading || isConnectedLinesLoading;

    const numLines = connectedLines.length + linesWithMissingBuses.length;
    const connectedLinesTitle = isLoading
        ? "Connected Lines"
        : `Connected Lines (${numLines})`;
    const icon = isLocked ? "lock" : isOpen ? "chevron-down" : "chevron-right";

    const onClick = () => {
        segmentTrackConnectedLinesSectionToggled(selectedBus.id, !isOpen);
        setOpen(!isOpen);
    };

    const noConnectedLines = numLines === 0 && !isLoading;
    const connectedLinesDetails = noConnectedLines ? (
        <div className="bp5-text-small bp5-text-disabled ConnectedLinesSection-empty-state">
            This bus has no connected lines. It is only connected to
            transformer(s) or other bus(es) within the substation.
        </div>
    ) : (
        <div>
            {connectedLines.map((lineTap) => (
                <LineTapRow key={lineTap.facilityId} lineTap={lineTap} />
            ))}
            {linesWithMissingBuses.map((lineWithMissingBus) => (
                <LineTapRowWithMissingBus
                    key={lineWithMissingBus.facilityId}
                    lineWithMissingBus={lineWithMissingBus}
                />
            ))}
        </div>
    );

    const allowClick = !isLoading && !isLocked;

    return (
        <div>
            <div
                className={classNames("ConnectedLinesSection-wrapper", {
                    "ConnectedLinesSection-allow-click": allowClick
                })}
                onClick={allowClick ? onClick : undefined}
            >
                <h6 className="bp5-heading">{connectedLinesTitle}</h6>
                <Icon className="bp5-text-muted" icon={icon} />
            </div>
            <Collapse isOpen={allowClick && isOpen}>
                {connectedLinesDetails}
            </Collapse>
        </div>
    );
};

interface LineTapRowProps {
    lineTap: ConnectedLine;
}
const LineTapRow: React.FC<LineTapRowProps> = (
    props: LineTapRowProps
): JSX.Element => {
    const { lineTap } = props;
    const {
        busFiltersConfiguration: {
            busFilters: { capacityThresholds }
        },
        boundingBoxConfiguration: { adjustBoundingBoxToShowLine }
    } = useMapViewConfiguration();
    const { generator, componentConfig } = useMapViewRoutingMetadata();
    const { bus: selectedBus } = useSelectedScopedBus();
    const { selectLineTap } = useSelectedBuses();

    const labelStyle: LabelStyleType = getLabelStyle(
        componentConfig,
        generator,
        true
    );

    const boundingBoxWithBothBuses = {
        maxLat: Math.max(lineTap.toBus.latitude, selectedBus.latitude),
        minLat: Math.min(lineTap.toBus.latitude, selectedBus.latitude),
        maxLong: Math.max(lineTap.toBus.longitude, selectedBus.longitude),
        minLong: Math.min(lineTap.toBus.longitude, selectedBus.longitude)
    };

    const onClick = () => {
        segmentTrackLineSelectedFromBusDetailPane(
            selectedBus.id,
            lineTap.facilityId
        );
        adjustBoundingBoxToShowLine(boundingBoxWithBothBuses);
        selectLineTap({
            facilityId: lineTap.facilityId,
            fromBusId: selectedBus.id,
            toBusId: lineTap.toBus.busId
        });
    };

    const energyTag = (
        <AvailableTag
            size={lineTap.energySize}
            resourceType={ENERGY_RESOURCE_TYPE}
            labelStyle={labelStyle}
            minimal
        />
    );
    const capacityTag = isPricedConstraintConfig(componentConfig) &&
        componentConfig.showCapacity && (
            <AvailableTag
                size={lineTap.capacitySize}
                resourceType={CAPACITY_RESOURCE_TYPE}
                labelStyle={labelStyle}
                minimal
            />
        );
    const chargingTag = generator === STORAGE_TYPE &&
        componentConfig.showChargingSize && (
            <AvailableTag
                size={lineTap.chargingSize}
                resourceType={CHARGING_RESOURCE_TYPE}
                labelStyle={labelStyle}
                minimal
            />
        );

    let secondConnectedLineDetail;
    if (isPricedConstraintConfig(componentConfig)) {
        const maybeAllocatedCostString = getFormattedAllocatedCost(
            lineTap.allocatedCosts
        );
        const totalCostString = getFormattedTotalCost(lineTap.totalCosts);
        secondConnectedLineDetail = componentConfig.hasAllocatedCost
            ? `${maybeAllocatedCostString} • ${totalCostString}`
            : totalCostString;
    } else {
        secondConnectedLineDetail = lineTap.toBus.owner;
    }

    return (
        <div
            className="ConnectedLinesSection-line-wrapper ConnectedLinesSection-allow-click"
            onClick={onClick}
        >
            <Icon
                className="ConnectedLinesSection-line-icon"
                icon="layout-linear"
                color={getCapacityColor(lineTap, capacityThresholds, generator)}
            />
            <div className="ConnectedLinesSection-line-details">
                <div>{lineTap.toBus.busName}</div>
                <div className="bp5-text-small bp5-text-disabled ConnectedLinesSection-line-row1">
                    <div>{lineTap.facilityId}</div>
                    <div>{lineTap.lineLength.toFixed(1)} miles</div>
                </div>
                <div className="bp5-text-small bp5-text-disabled">
                    {secondConnectedLineDetail}
                </div>
            </div>
            <div className="ConnectedLinesSection-line-tags">
                {energyTag}
                {capacityTag || chargingTag}
            </div>
        </div>
    );
};

interface LineTapRowWithMissingBusProps {
    lineWithMissingBus: LineWithMissingBus;
}

const LineTapRowWithMissingBus: React.FC<LineTapRowWithMissingBusProps> = (
    props: LineTapRowWithMissingBusProps
): JSX.Element => {
    const { lineWithMissingBus } = props;
    const { region, scopeView } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: {
            busFilters: { scope }
        }
    } = useMapViewConfiguration();

    const {
        missingBusId,
        queuedProjectsAtMissingBus,
        voltage,
        facilityId,
        lineLength,
        rating
    } = lineWithMissingBus;

    let busOrProjectName;
    let mailtoButton;
    if (queuedProjectsAtMissingBus !== undefined) {
        busOrProjectName = (
            <div className="ConnectedLinesSection-project-name-wrapper">
                <Text
                    ellipsize={true}
                    className="ConnectedLinesSection-project-name"
                >
                    {queuedProjectsAtMissingBus}
                </Text>
                <Text ellipsize={false}>- {voltage.toFixed(1)}kV</Text>
            </div>
        );
        const onClick = () =>
            ConnectedLinesSectionToaster.show({
                message:
                    "Nira is unable to add this bus. It's a placeholder bus created for a queued generator with an unknown tap location.",
                intent: Intent.WARNING,
                timeout: 10000
            });
        mailtoButton = (
            <div
                className="ConnectedLinesSection-missing-bus-mailto ConnectedLinesSection-more-info"
                onClick={onClick}
            >
                <div className="bp5-text-small bp5-text-disabled">
                    request bus
                </div>
                <Icon icon="envelope" color={GREY_COLOR} />
            </div>
        );
    } else {
        busOrProjectName = `Bus ${missingBusId} - ${voltage.toFixed(1)}kV`;
        const subject = `Missing Bus ${missingBusId} (${region})`;
        const body = `Hi%20Nira,%0A%0ACould%20you%20please%20add%20this%20missing%20bus%20from%20${scope}%20in%20${scopeView}?%0A%0ACoordinates%20(lat,%20lon):%0ASatellite%20screenshot:%0AAny%20additional%20known%20information:%0A%0AThank%20you!`;
        const mailto = `mailto:missing.buses@niraenergy.com?subject=${subject}&body=${body}`;
        mailtoButton = (
            <div
                className="ConnectedLinesSection-missing-bus-mailto ConnectedLinesSection-allow-click"
                onClick={() => window.open(mailto)}
            >
                <div className="bp5-text-small">request bus</div>
                <Icon icon="envelope" />
            </div>
        );
    }
    return (
        <div className="ConnectedLinesSection-line-wrapper">
            <Icon
                className="ConnectedLinesSection-line-icon"
                icon="layout-linear"
                color={GREY_COLOR}
            />
            <div className="ConnectedLinesSection-line-details">
                <div>{busOrProjectName}</div>
                <div className="bp5-text-small bp5-text-disabled ConnectedLinesSection-line-row1">
                    <div>{facilityId}</div>
                    <div>{lineLength.toFixed(1)} miles</div>
                    <div>{rating} MVA</div>
                </div>
            </div>
            {mailtoButton}
        </div>
    );
};

export default ConnectedLinesSection;
