import {
    ColDef,
    ColGroupDef,
    ValueFormatterFunc,
    ValueFormatterParams
} from "ag-grid-community";
import { ConstraintType } from "in_queue/types/scenarioResultType";
import { ScenarioMetadata, ScenarioStatus } from "in_queue/types/scenarioType";
import { formatCosts } from "in_queue/utils/formatting";
import { capitalize, startCase, toLower } from "lodash";
export interface NiraColDef<TData, TColumn extends string>
    extends ColDef<TData> {
    colId?: TColumn;
}

export interface NiraColGroupDef<TData, TColumn extends string>
    extends ColGroupDef<TData> {
    colId?: TColumn;
    children: (NiraColDef<TData, TColumn> | NiraColGroupDef<TData, TColumn>)[];
}

export type AgGridColumnDefs<TData, TColumn extends string> = (
    | NiraColDef<TData, TColumn>
    | NiraColGroupDef<TData, TColumn>
)[];

export const percentValueFormatter: ValueFormatterFunc = <T>(
    params: ValueFormatterParams<T, number>
) => (params.value ? `${params.value.toFixed(1)}%` : "-");

export const dfaxFormatter: ValueFormatterFunc = <T>(
    params: ValueFormatterParams<T, number>
) => (params.value != null ? `${(params.value * 100).toFixed(1)}%` : "-");

export const currencyFormatterHelper = (
    value: number | null | undefined
): string =>
    (value as unknown as string) === "Infinity"
        ? "-"
        : value != null
        ? formatCosts(value)
        : "-";

export const currencyFormatter: ValueFormatterFunc = <T>(
    params: ValueFormatterParams<T, number>
) =>
    // ag-grid converts JS numeric Infinity into a string "Infinity" for some reason
    currencyFormatterHelper(params.value);

export const roundingFormatter: ValueFormatterFunc = <T>(
    params: ValueFormatterParams<T, number>
) => {
    return (
        params.value?.toLocaleString(undefined, {
            useGrouping: false,
            maximumFractionDigits: 2
        }) ?? "-"
    );
};

export const titleCaseStringFormatter: ValueFormatterFunc = <T>(
    params: ValueFormatterParams<T, string>
) => (params.value ? capitalize(params.value) : "-");

export const stringFormatter: ValueFormatterFunc = <T>(
    params: ValueFormatterParams<T, string>
) => params.value ?? "-";

export const statusFormatter: ValueFormatterFunc<ScenarioMetadata, string> = (
    params
) => {
    const scenario = params.data;
    if (!scenario) {
        return "-";
    }

    if (scenario.status === ScenarioStatus.Draft) {
        return "Draft";
    } else if (
        scenario.status === ScenarioStatus.RunRequested ||
        scenario.status === ScenarioStatus.RunSubmitted ||
        scenario.status === ScenarioStatus.Running
    ) {
        return "Running";
    } else if (scenario.status === ScenarioStatus.RunSucceeded) {
        return "Done";
    } else if (scenario.status === ScenarioStatus.RunFailed) {
        return "Failed";
    } else if (scenario.status === ScenarioStatus.Archived) {
        return "Archived";
    }

    return "-";
};

export const timingFormatter: ValueFormatterFunc<ScenarioMetadata, string> = (
    params
) => {
    const scenario = params.data;
    if (!scenario) {
        return "-";
    }

    if (!params.value) {
        return "-";
    }

    return new Date(params.value).toLocaleString();
};

/**
 * These constraint types will be formatted in sentence case (e.g. "Summer Peak").
 * The rest will fall back to all-caps.
 */
const sentenceCaseConstraintTypes: Set<ConstraintType> = new Set([
    ConstraintType.SummerPeak,
    ConstraintType.LightLoad
]);
export const constraintTypeFormatter: ValueFormatterFunc = <T>(
    params: ValueFormatterParams<T, ConstraintType>
) => {
    const constraintType = params.value;
    if (constraintType == null) {
        return "";
    } else if (constraintType === ConstraintType.SharedNetworkUpgrade) {
        return "SNU";
    }
    if (sentenceCaseConstraintTypes.has(constraintType)) {
        return startCase(toLower(constraintType.replace("_", " ")));
    } else {
        return constraintType.replace("_", " ");
    }
};

// Custom type guard to determine if a column definition is a ColDef
export const isColDef = <TData, TColumn extends string>(
    columnDef: NiraColDef<TData, TColumn> | NiraColGroupDef<TData, TColumn>
): columnDef is NiraColDef<TData, TColumn> => {
    return "colId" in columnDef;
};

// Custom type guard to determine if a column definition is a ColGroupDef
export const isColGroupDef = <TData, TColumn extends string>(
    columnDef: NiraColDef<TData, TColumn> | NiraColGroupDef<TData, TColumn>
): columnDef is NiraColGroupDef<TData, TColumn> => {
    return "children" in columnDef;
};

export const hideUnwantedColumns = <TData, TColumn extends string>(
    columnDefs: AgGridColumnDefs<TData, TColumn>,
    columnsToHide: readonly TColumn[]
): void => {
    columnDefs.forEach((def) => {
        if (isColGroupDef(def)) {
            // Recursively process children for column groups
            hideUnwantedColumns(def.children, columnsToHide);
        } else if (
            isColDef(def) &&
            def.colId &&
            columnsToHide.includes(def.colId)
        ) {
            def.hide = true;
        }
    });
};

// Columns for the scenarios table on the project page
export type ScenariosTableColumnId =
    | "title"
    | "cost"
    | "costPerMw"
    | "energyMw"
    | "capacityMw"
    | "sensitivities"
    | "runStatus"
    | "timing"
    | "inputData"
    | "costAssumptions";

// Columns for the allocated upgrades table
export type AllocatedUpgradesTableColumnId =
    | "projectId"
    | "networkUpgradeId"
    | "networkUpgradeName"
    | "constraintType"
    | "allocatedCost"
    | "maxDfax"
    | "sumMwImpact"
    | "totalCost"
    | "facilityNames"
    | "notes"
    | "percentAllocated";

// Columns for the constraints table
export type ConstraintsTableColumnId =
    | "projectId"
    | "monitoredFacilityName"
    | "facilityDetails"
    | "constraintType"
    | "dfax"
    | "mwImpact"
    | "preStudyLoad"
    | "postStudyLoad"
    | "preStudyFlow"
    | "postStudyFlow"
    | "rating"
    | "worstContingency"
    | "networkUpgrades"
    | "notes"
    | "model";

// Columns for the scenario projects table
export type ScenarioProjectsTableColumnId =
    | "projectId"
    | "subregion"
    | "numSharedUpgrades"
    | "costPerMW"
    | "sumAllocatedCost"
    | "erisSize"
    | "nrisSize"
    | "erisThermal"
    | "erisLpc"
    | "erisComplex"
    | "nrisThermal"
    | "nrisComplex"
    | "erisSnu"
    | "assignedUpgrades";
