import { Intent } from "@blueprintjs/core";
import { PowerAmounts } from "components/detailPane/BusDetailPane/BusDetailPane";
import {
    ComponentConfig,
    isPricedConstraintConfig
} from "types/componentConfigPerRegion";
import { GeneratorType, LOAD_TYPE, STORAGE_TYPE } from "types/generatorType";
import {
    CAPACITY_RESOURCE_TYPE,
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE,
    ResourceType
} from "types/resourceType";

const ENERGY_CAPACITY_LABEL = "EnergyCapacityLabel";
const DISCHARGING_CHARGING_LABEL = "DischargingChargingLabel";
const DISCHARGING_CHARGING_CAPACITY_LABEL = "DischargingChargingCapacityLabel";
const LARGE_LOAD_LABEL = "LargeLoadLabel";

const LABEL_STYLES = [
    ENERGY_CAPACITY_LABEL,
    DISCHARGING_CHARGING_LABEL,
    DISCHARGING_CHARGING_CAPACITY_LABEL,
    LARGE_LOAD_LABEL
] as const;

export type LabelStyleType = (typeof LABEL_STYLES)[number];

export const getAllSizeLabels = (
    powerAmounts: PowerAmounts,
    showCapacity: boolean,
    showCharging: boolean,
    labelStyle: LabelStyleType
): string => {
    const maybeLabels = [
        `${powerAmounts.energySize} ${getSizeLabel(
            ENERGY_RESOURCE_TYPE,
            labelStyle
        )}`,
        showCharging
            ? `${powerAmounts.chargingSize} ${getSizeLabel(
                  CHARGING_RESOURCE_TYPE,
                  labelStyle
              )}`
            : undefined,
        showCapacity
            ? `${powerAmounts.capacitySize} ${getSizeLabel(
                  CAPACITY_RESOURCE_TYPE,
                  labelStyle
              )}`
            : undefined
    ];

    return maybeLabels
        .filter((maybeLabel) => maybeLabel !== undefined)
        .join(", ");
};

export const getLabelStyle = (
    componentConfig: ComponentConfig,
    generatorType: GeneratorType,
    onlyShowTwoLabels?: boolean
): LabelStyleType => {
    if (isPricedConstraintConfig(componentConfig)) {
        if (generatorType === LOAD_TYPE) {
            // I doubt we'll ever see a curtailment type large load, but we'll need to change this if we do
            return LARGE_LOAD_LABEL;
        } else {
            if (componentConfig.showCapacity) {
                if (
                    generatorType === STORAGE_TYPE &&
                    componentConfig.showChargingSize &&
                    !onlyShowTwoLabels
                ) {
                    return DISCHARGING_CHARGING_CAPACITY_LABEL;
                } else {
                    return ENERGY_CAPACITY_LABEL;
                }
            } else {
                return DISCHARGING_CHARGING_LABEL;
            }
        }
    } else {
        return DISCHARGING_CHARGING_LABEL;
    }
};

export const getSizeLabel = (
    resourceType: ResourceType,
    labelStyle: LabelStyleType
): string => {
    if (labelStyle === DISCHARGING_CHARGING_LABEL) {
        if (resourceType === ENERGY_RESOURCE_TYPE) {
            return "MW (D)";
        } else if (resourceType === CHARGING_RESOURCE_TYPE) {
            return "MW (C)";
        } else {
            return "Unexpected";
        }
    } else if (labelStyle === ENERGY_CAPACITY_LABEL) {
        if (resourceType === ENERGY_RESOURCE_TYPE) {
            return "MWE";
        } else if (resourceType === CHARGING_RESOURCE_TYPE) {
            return "Unexpected";
        } else {
            return "MWC";
        }
    } else if (labelStyle === DISCHARGING_CHARGING_CAPACITY_LABEL) {
        if (resourceType === ENERGY_RESOURCE_TYPE) {
            return "MWE (D)";
        } else if (resourceType === CHARGING_RESOURCE_TYPE) {
            return "MWE (C)";
        } else {
            return "MWC";
        }
    } else {
        // Large load only shows MW
        if (resourceType === ENERGY_RESOURCE_TYPE) {
            return "MW";
        } else if (resourceType === CHARGING_RESOURCE_TYPE) {
            return "Unexpected";
        } else {
            return "Unexpected";
        }
    }
};

export const getResourceTypeLabel = (
    resourceType: ResourceType,
    labelStyle: LabelStyleType
): string => {
    if (labelStyle === DISCHARGING_CHARGING_LABEL) {
        if (resourceType === ENERGY_RESOURCE_TYPE) {
            return "discharging";
        } else if (resourceType === CHARGING_RESOURCE_TYPE) {
            return "charging";
        } else {
            return "unexpected";
        }
    } else if (labelStyle === ENERGY_CAPACITY_LABEL) {
        if (resourceType === ENERGY_RESOURCE_TYPE) {
            return "energy";
        } else if (resourceType === CHARGING_RESOURCE_TYPE) {
            return "unexpected";
        } else {
            return "capacity";
        }
    } else if (labelStyle === DISCHARGING_CHARGING_CAPACITY_LABEL) {
        if (resourceType === ENERGY_RESOURCE_TYPE) {
            return "energy (discharge)";
        } else if (resourceType === CHARGING_RESOURCE_TYPE) {
            return "energy (charge)";
        } else {
            return "capacity";
        }
    } else {
        // We should only hit this with large load, and in that case, we hide the resource type column
        return "unexpected";
    }
};

export const getPillIntent = (
    resourceType: ResourceType,
    labelStyle: LabelStyleType
): Intent => {
    if (
        labelStyle === DISCHARGING_CHARGING_LABEL ||
        labelStyle === ENERGY_CAPACITY_LABEL ||
        labelStyle === LARGE_LOAD_LABEL
    ) {
        if (resourceType === ENERGY_RESOURCE_TYPE) {
            return Intent.PRIMARY;
        } else {
            return Intent.NONE;
        }
    } else {
        if (
            resourceType === ENERGY_RESOURCE_TYPE ||
            resourceType === CHARGING_RESOURCE_TYPE
        ) {
            return Intent.PRIMARY;
        } else {
            return Intent.NONE;
        }
    }
};
