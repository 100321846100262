import { isNumberArray } from "graphql/helpers/queryHelpers";
import { RegionType } from "./regionType";

export const KV_44 = 44;
export const KV_66 = 66;
export const KV_69 = 69;
export const KV_100 = 100;
export const KV_115 = 115;
export const KV_120 = 120;
export const KV_138 = 138;
export const KV_161 = 161;
export const KV_230 = 230;
export const KV_345 = 345;
export const KV_500 = 500;
export const KV_765 = 765;

export const VOLTAGE_TYPES_IN_ORDER = [
    KV_44,
    KV_66,
    KV_69,
    KV_100,
    KV_115,
    KV_120,
    KV_138,
    KV_161,
    KV_230,
    KV_345,
    KV_500,
    KV_765
] as const;

export type Voltage = (typeof VOLTAGE_TYPES_IN_ORDER)[number];

export const REGIONS_TO_VOLTAGES: {
    readonly [key in RegionType]: ReadonlyArray<Voltage>;
} = {
    NYISO: [KV_69, KV_115, KV_138, KV_230, KV_345, KV_500, KV_765],
    PJM: [KV_69, KV_115, KV_138, KV_161, KV_230, KV_345, KV_500, KV_765],
    MISO: [KV_69, KV_115, KV_120, KV_138, KV_161, KV_230, KV_345, KV_500],
    CAISO: [KV_66, KV_69, KV_115, KV_138, KV_161, KV_230, KV_500],
    SPP: [KV_69, KV_115, KV_138, KV_161, KV_230, KV_345],
    ERCOT: [KV_69, KV_138, KV_345],
    WECC: [
        KV_69,
        KV_100,
        KV_115,
        KV_120,
        KV_138,
        KV_161,
        KV_230,
        KV_345,
        KV_500
    ],
    TVA: [KV_69, KV_115, KV_138, KV_161, KV_230, KV_345, KV_500],
    ISONE: [KV_69, KV_115, KV_120, KV_138, KV_230, KV_345],
    SOCO: [KV_115, KV_161, KV_230, KV_500],
    DUKE: [KV_44, KV_66, KV_69, KV_100, KV_115, KV_138, KV_161, KV_230, KV_500]
};

export const isKnownVoltageType = (type: number): type is Voltage => {
    return VOLTAGE_TYPES_IN_ORDER.includes(type as Voltage);
};

export const convertToVoltage = (hasuraVoltage: number): Voltage => {
    if (!isKnownVoltageType(hasuraVoltage)) {
        throw new Error(`Unexpected voltage${hasuraVoltage}`);
    }
    return hasuraVoltage;
};

export const convertHasuraAnyToVoltages = (
    hasuraObject: unknown
): Voltage[] => {
    if (!isNumberArray(hasuraObject)) {
        throw new Error("Expected array of numbers");
    }

    const listOfNumbers: number[] = hasuraObject;
    const validStates: Voltage[] = listOfNumbers
        .filter(isKnownVoltageType)
        .map(convertToVoltage);

    return validStates;
};
