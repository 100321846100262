import { ComponentConfig } from "types/componentConfigPerRegion";
import { Constraint } from "types/constraintType";
import { GeneratorType, STORAGE_TYPE } from "types/generatorType";
import { LineTapConstraint } from "types/lineConstraintType";
import { Loading } from "types/loadingType";
import {
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE
} from "types/resourceType";
import { PowerAmounts } from "./BusDetailPane";
import { ConstraintDetailRowData } from "./ConstraintsDetailsSection";

type ConstraintWithAllocatedCost = {
    readonly constraint: Constraint | LineTapConstraint;
    readonly allocatedCost: number;
};

export const getTotalCostForConstraintsForSize = (
    constraints: ReadonlyArray<Constraint | LineTapConstraint>,
    powerAmounts: PowerAmounts
): number => {
    const relevantConstraints = getRelevantConstraintsForSize(
        constraints,
        powerAmounts
    );

    const dedupedAllocatedConstraints = dedupeConstraintsBasedOnAllocatedCosts(
        convertConstraintsToConstraintsWithAllocatedCosts(
            relevantConstraints,
            powerAmounts
        )
    );

    return dedupedAllocatedConstraints.reduce((prev, current) => {
        const estimatedCost = current.constraint.estimatedCost;
        if (estimatedCost === undefined) {
            return prev;
        } else {
            return prev + estimatedCost;
        }
    }, 0);
};

export const getTotalAllocatedCostForConstraintsForSize = (
    constraints: ReadonlyArray<Constraint | LineTapConstraint>,
    powerAmounts: PowerAmounts
): number => {
    const relevantConstraints = getRelevantConstraintsForSize(
        constraints,
        powerAmounts
    );

    const dedupedAllocatedConstraints = dedupeConstraintsBasedOnAllocatedCosts(
        convertConstraintsToConstraintsWithAllocatedCosts(
            relevantConstraints,
            powerAmounts
        )
    );

    return dedupedAllocatedConstraints.reduce((prev, current) => {
        return prev + current.allocatedCost;
    }, 0);
};

export const getTotalNumberConstraintsForSize = (
    constraints: ReadonlyArray<Constraint | LineTapConstraint>,
    powerAmounts: PowerAmounts
): number => {
    const numberConstraintsForSize = getRelevantConstraintsForSize(
        constraints,
        powerAmounts
    );

    return numberConstraintsForSize.reduce(
        (set, nextConstraint) => set.add(nextConstraint.monitoredFacilityId),
        new Set()
    ).size;
};

export const getRelevantConstraintsForSize = (
    constraints: ReadonlyArray<Constraint | LineTapConstraint>,
    powerAmounts: PowerAmounts
): ReadonlyArray<Constraint | LineTapConstraint> => {
    return constraints.filter((constraint) =>
        isConstraintWithinSize(constraint, powerAmounts)
    );
};

export const getConstraintsOutsideSize = (
    constraints: ReadonlyArray<Constraint | LineTapConstraint>,
    powerAmounts: PowerAmounts
): ReadonlyArray<Constraint | LineTapConstraint> => {
    return constraints.filter(
        (constraint) => !isConstraintWithinSize(constraint, powerAmounts)
    );
};

export const isConstraintWithinSize = (
    constraint: Constraint | LineTapConstraint,
    powerAmounts: PowerAmounts
): boolean => {
    const { energySize, capacitySize, chargingSize } = powerAmounts;
    if (constraint.resourceType === ENERGY_RESOURCE_TYPE) {
        return constraint.injectionThreshold < energySize;
    } else if (constraint.resourceType === CHARGING_RESOURCE_TYPE) {
        return constraint.injectionThreshold < chargingSize;
    } else {
        return constraint.injectionThreshold < capacitySize;
    }
};

const dedupeConstraintsBasedOnAllocatedCosts = (
    constraints: ReadonlyArray<ConstraintWithAllocatedCost>
): ReadonlyArray<ConstraintWithAllocatedCost> => {
    const sortedConstraints: ReadonlyArray<ConstraintWithAllocatedCost> = [
        ...constraints
    ].sort(
        (a: ConstraintWithAllocatedCost, b: ConstraintWithAllocatedCost) =>
            b.allocatedCost - a.allocatedCost
    );

    let dedupedConstraints: ReadonlyArray<ConstraintWithAllocatedCost> = [];
    sortedConstraints.forEach((currentConstraint) => {
        if (
            !dedupedConstraints.some(
                (alreadySeenConstraint) =>
                    alreadySeenConstraint.constraint.monitoredFacilityId ===
                    currentConstraint.constraint.monitoredFacilityId
            )
        ) {
            dedupedConstraints = [...dedupedConstraints, currentConstraint];
        }
    });

    return dedupedConstraints;
};

const convertConstraintsToConstraintsWithAllocatedCosts = (
    constraints: ReadonlyArray<Constraint | LineTapConstraint>,
    powerAmounts: PowerAmounts
): ReadonlyArray<ConstraintWithAllocatedCost> => {
    return constraints.map((constraint) => {
        return {
            constraint,
            allocatedCost: getAllocatedCostForConstraint(
                constraint,
                powerAmounts
            )
        };
    });
};

export const getAllocatedCostForConstraint = (
    constraint: Constraint | LineTapConstraint,
    powerAmounts: PowerAmounts
): number => {
    const { energySize, capacitySize, chargingSize } = powerAmounts;
    const totalEstimatedCost = constraint.estimatedCost;

    if (totalEstimatedCost === undefined) {
        return 0;
    }

    let injectionSize;
    if (constraint.resourceType === ENERGY_RESOURCE_TYPE) {
        injectionSize = energySize;
    } else if (constraint.resourceType === CHARGING_RESOURCE_TYPE) {
        injectionSize = chargingSize;
    } else {
        injectionSize = capacitySize;
    }

    const impact_as_percent_of_rating =
        (constraint.dfax * injectionSize) / constraint.rating;

    const percent_allocation = Math.min(
        1.3 * Math.log10(impact_as_percent_of_rating + 0.16) + 1.117,
        1
    );

    return percent_allocation * totalEstimatedCost;
};

export const getConstraintDetailRows = (
    maybeConstraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>,
    originalEnergyAndCapacity: PowerAmounts,
    editedEnergyAndCapacity: PowerAmounts,
    hasAllocatedCost: boolean
): ReadonlyArray<ConstraintDetailRowData<number>> => {
    const constraintDetailsRows = [
        {
            label: "Total cost",
            originalValue: getTotalCost(
                maybeConstraints,
                originalEnergyAndCapacity
            ),
            editedValue: getTotalCost(
                maybeConstraints,
                editedEnergyAndCapacity
            ),
            formatter: getFormattedCost
        },
        {
            label: "Number of constraints",
            originalValue: getNumTotalConstraints(
                maybeConstraints,
                originalEnergyAndCapacity
            ),
            editedValue: getNumTotalConstraints(
                maybeConstraints,
                editedEnergyAndCapacity
            ),
            formatter: getFormattedConstraints,
            doNotFormatOriginalWhenCrossed: true
        }
    ];
    if (hasAllocatedCost) {
        constraintDetailsRows.unshift({
            label: "Allocated cost",
            originalValue: getAllocatedCost(
                maybeConstraints,
                originalEnergyAndCapacity
            ),
            editedValue: getAllocatedCost(
                maybeConstraints,
                editedEnergyAndCapacity
            ),
            formatter: getFormattedCost
        });
    }

    return constraintDetailsRows;
};

export const getAllocatedCost = (
    maybeConstraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>,
    powerAmounts: PowerAmounts
): Loading<number> => {
    return maybeConstraints === "loading"
        ? "loading"
        : getTotalAllocatedCostForConstraintsForSize(
              maybeConstraints,
              powerAmounts
          );
};

export const getTotalCost = (
    maybeConstraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>,
    powerAmounts: PowerAmounts
): Loading<number> => {
    return maybeConstraints === "loading"
        ? "loading"
        : getTotalCostForConstraintsForSize(maybeConstraints, powerAmounts);
};

const getNumTotalConstraints = (
    maybeConstraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>,
    powerAmounts: PowerAmounts
): Loading<number> => {
    return maybeConstraints === "loading"
        ? "loading"
        : getTotalNumberConstraintsForSize(maybeConstraints, powerAmounts);
};

export const getFormattedAllocatedCost = (
    cost: Loading<number>
): Loading<string> => {
    if (cost === "loading") {
        return "loading";
    }

    return `${getFormattedCost(cost)} allocated`;
};

export const getFormattedTotalCost = (
    cost: Loading<number>
): Loading<string> => {
    if (cost === "loading") {
        return "loading";
    }

    return `${getFormattedCost(cost)} total`;
};

export const getFormattedCost = (cost: number): string => {
    return `$${(cost / 1000000).toFixed(1)}M`;
};

const getFormattedConstraints = (constraints: number): string => {
    return `${constraints} ${constraints === 1 ? "constraint" : "constraints"}`;
};

export const getCurtailmentDetailRows = (
    maybeConstraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>,
    generatorType: GeneratorType,
    componentConfig: ComponentConfig
): ReadonlyArray<
    ConstraintDetailRowData<Constraint | LineTapConstraint | undefined>
> => {
    let detailsRows = [
        {
            label: "1st discharging constraint",
            originalValue: getFirstDischargingConstraint(maybeConstraints),
            formatter: getFirstConstraintFormatter(componentConfig)
        }
    ];

    if (generatorType === STORAGE_TYPE && componentConfig.showChargingSize) {
        detailsRows = [
            ...detailsRows,
            {
                label: "1st charging constraint",
                originalValue: getFirstChargingConstraint(maybeConstraints),
                formatter: getFirstConstraintFormatter(componentConfig)
            }
        ];
    }

    return detailsRows;
};

const getFirstDischargingConstraint = (
    maybeConstraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>
): Loading<Constraint | LineTapConstraint | undefined> => {
    if (maybeConstraints === "loading") {
        return "loading";
    }

    const dischargingConstraints = maybeConstraints.filter(
        (maybeConstraints) =>
            maybeConstraints.resourceType === ENERGY_RESOURCE_TYPE
    );

    return sortAndReturnLowestInjectionThresholdConstraint(
        dischargingConstraints
    );
};

const getFirstChargingConstraint = (
    maybeConstraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>
): Loading<Constraint | LineTapConstraint | undefined> => {
    if (maybeConstraints === "loading") {
        return "loading";
    }

    const chargingConstraints = maybeConstraints.filter(
        (maybeConstraints) =>
            maybeConstraints.resourceType === CHARGING_RESOURCE_TYPE
    );

    return sortAndReturnLowestInjectionThresholdConstraint(chargingConstraints);
};

const sortAndReturnLowestInjectionThresholdConstraint = (
    constraints: ReadonlyArray<Constraint | LineTapConstraint>
): Constraint | LineTapConstraint | undefined => {
    const sortedEnergyConstraints = [...constraints].sort(
        (a, b) => a.injectionThreshold - b.injectionThreshold
    );

    if (sortedEnergyConstraints.length === 0) {
        return undefined;
    } else {
        return sortedEnergyConstraints[0];
    }
};

const getFirstConstraintFormatter = (
    componentConfig: ComponentConfig
): ((
    firstConstraint: Loading<Constraint | LineTapConstraint | undefined>
) => Loading<string>) => {
    return (
        firstConstraint: Loading<Constraint | LineTapConstraint | undefined>
    ): Loading<string> => {
        if (firstConstraint === "loading") {
            return "loading";
        }

        if (firstConstraint === undefined) {
            return "No constraints";
        }

        const formattedDfax = componentConfig.missingBranchMetadata
            ? ""
            : `, ${(firstConstraint.dfax * 100).toFixed(0)}% Dfax`;
        return `${Math.floor(
            firstConstraint.injectionThreshold
        )} MW${formattedDfax}`;
    };
};
