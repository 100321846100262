import { Constraint } from "types/constraintType";
import { GeneratorType, STORAGE_TYPE } from "types/generatorType";
import { LineTapConstraint } from "types/lineConstraintType";
import { Loading } from "types/loadingType";
import { CAISO, ERCOT, NYISO, RegionType } from "types/regionType";
import {
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE,
    ResourceType
} from "types/resourceType";
import {
    CAISO_SOLAR_SCOPE_VIEW,
    ErcotScopeViewType,
    ERCOT_HRML_DISCHARGING_SCOPE_VIEW,
    ERCOT_SP_CHARGING_SCOPE_VIEW,
    ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW,
    isKnownErcotScopeViewType,
    NYISO_SP_DISCHARGING_LL_CHARGING_SCOPE_VIEW,
    ScopeViewType
} from "types/scopeViewType";

export const CURTAILMENT_REGIONS = [ERCOT, NYISO, CAISO] as const;
export type CurtailmentRegionType = (typeof CURTAILMENT_REGIONS)[number];

export const isKnownCurtailmentRegion = (
    type: RegionType
): type is CurtailmentRegionType => {
    return CURTAILMENT_REGIONS.includes(type as CurtailmentRegionType);
};

export const convertToCurtailmentRegion = (
    region: RegionType
): CurtailmentRegionType => {
    if (isKnownCurtailmentRegion(region)) {
        return region;
    }

    throw new Error("Unexpected region type for Curtailment");
};

const EnergyOrChargingResourceTypes = [
    ENERGY_RESOURCE_TYPE,
    CHARGING_RESOURCE_TYPE
] as const;
export type EnergyOrChargingResourceType =
    (typeof EnergyOrChargingResourceTypes)[number];
const isEnergyOrChargingResourceType = (
    type: ResourceType
): type is EnergyOrChargingResourceType => {
    return EnergyOrChargingResourceTypes.includes(
        type as EnergyOrChargingResourceType
    );
};

// These represent different models. In ERCOT, it maps to Summer Peak and HRML.
// In NYISO, they represent Summer peak and Low load.
export const SUMMER_PEAK = "SUMMER_PEAK";
export const LOW_LOAD = "LOW_LOAD";
export const ModelTypes = [SUMMER_PEAK, LOW_LOAD] as const;
export type ModelType = (typeof ModelTypes)[number];

// This types maps to whether the boxes are checked inside the ConstraintStack table
export type ActivatedScopeViewsType = {
    readonly [key in EnergyOrChargingResourceType]: {
        readonly [key in ModelType]: boolean;
    };
};

// This maps the ScopeViews to the appropriate HWLL/SP Discharging/Charging checkbox.
type ScopeViewMappings = {
    readonly [key in EnergyOrChargingResourceType]: {
        readonly [key in ModelType]: ScopeViewType | undefined;
    };
};

const ERCOT_SCOPE_VIEW_MAPPINGS: ScopeViewMappings = {
    [ENERGY_RESOURCE_TYPE]: {
        [SUMMER_PEAK]: ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW,
        [LOW_LOAD]: ERCOT_HRML_DISCHARGING_SCOPE_VIEW
    },
    [CHARGING_RESOURCE_TYPE]: {
        [SUMMER_PEAK]: ERCOT_SP_CHARGING_SCOPE_VIEW,
        [LOW_LOAD]: ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW
    }
};

const NYISO_SCOPE_VIEW_MAPPINGS: ScopeViewMappings = {
    [ENERGY_RESOURCE_TYPE]: {
        [SUMMER_PEAK]: NYISO_SP_DISCHARGING_LL_CHARGING_SCOPE_VIEW,
        [LOW_LOAD]: undefined
    },
    [CHARGING_RESOURCE_TYPE]: {
        [SUMMER_PEAK]: undefined,
        [LOW_LOAD]: NYISO_SP_DISCHARGING_LL_CHARGING_SCOPE_VIEW
    }
};
const CAISO_SCOPE_VIEW_MAPPINGS: ScopeViewMappings = {
    [ENERGY_RESOURCE_TYPE]: {
        [SUMMER_PEAK]: CAISO_SOLAR_SCOPE_VIEW,
        [LOW_LOAD]: undefined
    },
    [CHARGING_RESOURCE_TYPE]: {
        [SUMMER_PEAK]: undefined,
        [LOW_LOAD]: undefined
    }
};

export const REGIONS_TO_SCOPE_VIEW_CONFIGURATION: {
    [key in CurtailmentRegionType]: ScopeViewMappings;
} = {
    ERCOT: ERCOT_SCOPE_VIEW_MAPPINGS,
    NYISO: NYISO_SCOPE_VIEW_MAPPINGS,
    CAISO: CAISO_SCOPE_VIEW_MAPPINGS
};

const getRelevantResourceTypes = (generatorType: GeneratorType) => {
    const resourceTypesRelevant: EnergyOrChargingResourceType[] = [
        ENERGY_RESOURCE_TYPE
    ];

    // Completely filter out charging constraints unless we're using the storage module
    if (generatorType === STORAGE_TYPE) {
        resourceTypesRelevant.push(CHARGING_RESOURCE_TYPE);
    }

    return resourceTypesRelevant;
};

// This function takes a scopeView and figures out, what its activated checkboxes are
export const getActivatedScopeViews = (
    region: CurtailmentRegionType,
    selectedScopeView: ScopeViewType,
    generatorType: GeneratorType
): ActivatedScopeViewsType => {
    const scopeViewMapping = REGIONS_TO_SCOPE_VIEW_CONFIGURATION[region];

    const resourceTypesRelevant = getRelevantResourceTypes(generatorType);

    let activatedScopeViews = {
        [ENERGY_RESOURCE_TYPE]: { [SUMMER_PEAK]: false, [LOW_LOAD]: false },
        [CHARGING_RESOURCE_TYPE]: { [SUMMER_PEAK]: false, [LOW_LOAD]: false }
    };

    resourceTypesRelevant.forEach((resourceType) => {
        ModelTypes.forEach((modelType) => {
            const scopeView = scopeViewMapping[resourceType][modelType];
            if (scopeView === selectedScopeView) {
                activatedScopeViews = {
                    ...activatedScopeViews,
                    [resourceType]: {
                        ...activatedScopeViews[resourceType],
                        [modelType]: true
                    }
                };
            }
        });
    });

    return activatedScopeViews;
};

export const filterDownConstraints = (
    constraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>,
    generatorType: GeneratorType,
    activatedScopeViews: ActivatedScopeViewsType,
    region: CurtailmentRegionType
): Loading<ReadonlyArray<Constraint | LineTapConstraint>> => {
    if (constraints === "loading") {
        return "loading";
    }

    const resourceTypesRelevant: EnergyOrChargingResourceType[] = [
        ENERGY_RESOURCE_TYPE
    ];

    // Completely filter out charging constraints unless we're using the storage module
    if (generatorType === STORAGE_TYPE) {
        resourceTypesRelevant.push(CHARGING_RESOURCE_TYPE);
    }

    let constraintsToReturn: ReadonlyArray<Constraint | LineTapConstraint> = [];

    // Go through resources (charging + discharging), and each model. Basically go through
    // each checkbox. Figure out whether its checked or not. If it's checked,
    // figure out which scopeView to use for that combo of resource/model given your current region
    // and then filter. Sometimes, a scope has both charging and discharging constraints
    // so we need a resource type filter as well.
    resourceTypesRelevant.forEach((resourceType) => {
        ModelTypes.forEach((modelType) => {
            const isActivated = activatedScopeViews[resourceType][modelType];
            if (isActivated) {
                const scopeViewMapping =
                    REGIONS_TO_SCOPE_VIEW_CONFIGURATION[region];
                const maybeScopeViewToUse =
                    scopeViewMapping[resourceType][modelType];
                // If this is undefined, that means we're using a model/resource type that isn't supported
                if (maybeScopeViewToUse !== undefined) {
                    const constraintsToAdd = constraints.filter(
                        (constraint) =>
                            constraint.scopeView === maybeScopeViewToUse &&
                            constraint.resourceType === resourceType
                    );

                    constraintsToReturn = [
                        ...constraintsToReturn,
                        ...constraintsToAdd
                    ];
                }
            }
        });
    });

    return constraintsToReturn;
};

// TODO: Make this generic to work for more regions beyond ERCOT.
const ERCOT_SUMMER_PEAK_LABEL = "Summer Peak";
const ERCOT_HIGH_RENEWABLES_MIN_LOAD_LABEL = "High Renewables Minimum Load";
export const MODEL_TYPE_TO_MODEL_LABELS: {
    readonly [key in ModelType]: string;
} = {
    SUMMER_PEAK: ERCOT_SUMMER_PEAK_LABEL,
    LOW_LOAD: ERCOT_HIGH_RENEWABLES_MIN_LOAD_LABEL
};

// TODO: Make this generic for all regions. This function takes a constraint, and reverse engineers its model label.
export const getErcotModelNameFromConstraint = (
    constraint: Constraint | LineTapConstraint
): string => {
    const ercotScopeViewAndResourceToModel: {
        readonly [key in ErcotScopeViewType]: {
            readonly [key in EnergyOrChargingResourceType]:
                | ModelType
                | undefined;
        };
    } = {
        [ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW]: {
            [ENERGY_RESOURCE_TYPE]: SUMMER_PEAK,
            [CHARGING_RESOURCE_TYPE]: LOW_LOAD
        },
        [ERCOT_SP_CHARGING_SCOPE_VIEW]: {
            [ENERGY_RESOURCE_TYPE]: undefined,
            [CHARGING_RESOURCE_TYPE]: SUMMER_PEAK
        },
        [ERCOT_HRML_DISCHARGING_SCOPE_VIEW]: {
            [ENERGY_RESOURCE_TYPE]: LOW_LOAD,
            [CHARGING_RESOURCE_TYPE]: undefined
        }
    };

    // If it returns unknown, it should never happen and that means our data is
    // in a state that is inconsistent
    const unknownModel = "unknown";

    const scopeView = constraint.scopeView;
    if (!isKnownErcotScopeViewType(scopeView)) {
        return unknownModel;
    }

    const resourceType = constraint.resourceType;
    if (!isEnergyOrChargingResourceType(resourceType)) {
        return unknownModel;
    }

    const ercotModel =
        ercotScopeViewAndResourceToModel[scopeView][resourceType];

    if (ercotModel === undefined) {
        return unknownModel;
    }

    return MODEL_TYPE_TO_MODEL_LABELS[ercotModel];
};
